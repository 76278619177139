import React, { FunctionComponent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  PromoOffer,
  computePaymentTypeValues,
  ComputedPaymentTypeValues,
  EXAMPLE_INSTALLMENTS_PROMO_OFFER,
  computeDefaultMerchantPaymentTypeValues,
} from '@ads-bread/shared/bread/util';
import {
  useMerchantPaymentProducts,
  useOrder,
  useProgramValues,
  useSDKPromoOffers,
} from '../../XPropsContext';
import { BreadLogo } from '../../svgs/BreadLogo';
import { useTierCustomizations } from '../../MerchantContext';
import { SVGIcon } from '../../../components/svgs/svgHelpers';
import { logger } from '../../../lib/logger';
import { FCWithChildren } from '../../../lib/types';
import { RouteMachineServiceScreenProps } from '../../RouteMachineService';

export type ABTestTwoResultType =
  | 'intro-default'
  | 'intro-email-added'
  | 'intro-single-stack-dual-side-by-side-email-added'
  | 'intro-single-stack-dual-side-by-side';

export type InstallmentsCardProps = {
  paymentTypeValues: ComputedPaymentTypeValues;
  splitPayPaymentTypeValues?: ComputedPaymentTypeValues | null;
  installmentIcon: SVGIcon;
  isDefaultOrder?: boolean;
  className?: string;
  abTestTwoResultType?: ABTestTwoResultType;
};

export const formattingValues: Record<string, any> = {
  sup: (chunks: ReactNode) => (
    <span className="text-xs font-normal align-top">{chunks}</span>
  ),
};

export type IntroTitle = { className?: string };

export const IntroTitle: FCWithChildren<IntroTitle> = ({
  className = '',
  children,
}) => {
  return (
    <h1
      className={`font-extrabold leading-normal text-center ${
        className ? className : `mb-4`
      }`}
      style={{ fontSize: '2.5rem' }}
    >
      {children}
    </h1>
  );
};

export type BreadBrandingSubheader = { className?: string };

export const BreadBrandingSubheader: FunctionComponent<BreadBrandingSubheader> =
  ({ className = '' }) => {
    return (
      <h2
        data-testid="bread-branding-subheader"
        className={`flex items-center justify-center text-lg ${className}`}
      >
        <FormattedMessage
          defaultMessage="Payment plans powered by"
          description="Intro screen sub heading"
        />

        <BreadLogo
          className="w-16 h-6 pl-1"
          fill="#0F2233"
          fillLogo="#0F2233"
        />
      </h2>
    );
  };

export const IntroBaseSubHeading: FCWithChildren = ({ children }) => {
  const { showIntroPageBreadSubHeading } = useTierCustomizations();

  return showIntroPageBreadSubHeading ? (
    <BreadBrandingSubheader />
  ) : (
    <h2 className="text-lg leading-normal text-center mb-4">{children}</h2>
  );
};

export const IntroSubHeading: FCWithChildren = ({ children }) => {
  return (
    <h2 className="text-lg leading-normal text-center mb-2">{children}</h2>
  );
};

export const PoweredBySubHeading: FunctionComponent = () => {
  const { showIntroPageBreadSubHeading } = useTierCustomizations();

  return showIntroPageBreadSubHeading ? (
    <h2
      data-testid="bread-branding-subheader"
      className="flex items-center justify-center text-lg mb-4"
    >
      <FormattedMessage
        defaultMessage="powered by"
        description="Intro screen powered by sub heading"
      />

      <BreadLogo className="w-20 ml-1" fill="#0F2233" fillLogo="#0F2233" />
    </h2>
  ) : null;
};
interface PromoOffersByType {
  [type: string]: PromoOffer | never;
}

export function mapPromoOffersByType(
  promoOffers?: PromoOffer[]
): PromoOffersByType {
  return !promoOffers
    ? {}
    : promoOffers.reduce((promoOffersByType, promoOffer) => {
        return {
          ...promoOffersByType,
          [promoOffer.type]: promoOffer,
        };
      }, {});
}

type IntroChildrenProps = {
  hasInstallments: boolean;
  hasSplitpay: boolean;
  sdkInstallmentsPaymentTypeValues: ComputedPaymentTypeValues | null;
  sdkSplitPayPaymentTypeValues: ComputedPaymentTypeValues | null;
  isDefaultOrder: boolean;
  synthesizedInstallmentsPaymentValues: ComputedPaymentTypeValues | null;
  synthesizedSplitPayPaymentValues: ComputedPaymentTypeValues | null;
  exampleInstallmentsPaymentTypeValues: ComputedPaymentTypeValues | null;
  useSyntheticPaymentTypeValues: boolean;
};

type IntroScreenProps = {
  children: ((props: IntroChildrenProps) => JSX.Element) | React.ReactNode;
};

const IntroScreen: FunctionComponent<
  RouteMachineServiceScreenProps & IntroScreenProps
> = ({ children }) => {
  const promoOffers = useSDKPromoOffers();
  const { merchantPaymentProducts } = useMerchantPaymentProducts();
  const { isDefaultOrder } = useOrder();
  const { displayInterestRateBPS } = useProgramValues();

  if (!merchantPaymentProducts?.length) {
    logger.error('Expected merchant payment product to not be empty.');
    return null;
  }

  const hasSDKOffers = promoOffers.length > 0;

  const sdkPromoOffersByType =
    hasSDKOffers && mapPromoOffersByType(promoOffers);

  const sdkInstallmentsPromoOffer =
    sdkPromoOffersByType && sdkPromoOffersByType['INSTALLMENTS'];

  const sdkSplitPayPromoOffer =
    sdkPromoOffersByType && sdkPromoOffersByType['SPLITPAY'];

  const sdkInstallmentsPaymentTypeValues =
    sdkInstallmentsPromoOffer &&
    computePaymentTypeValues(
      merchantPaymentProducts,
      sdkInstallmentsPromoOffer
    );

  const sdkSplitPayPaymentTypeValues =
    sdkSplitPayPromoOffer &&
    computePaymentTypeValues(merchantPaymentProducts, sdkSplitPayPromoOffer);

  const useSyntheticPaymentTypeValues = !hasSDKOffers;

  /**
   * RBC - Used under all circumstances (Cards, Disclosures)
   * ADS - Not used
   */
  const synthesizedInstallmentsPaymentValues =
    computeDefaultMerchantPaymentTypeValues(
      'INSTALLMENTS',
      merchantPaymentProducts,
      displayInterestRateBPS
    );

  /**
   * RBC - Not used
   * ADS - Used when promo offers are not available from the SDK (Cards, Disclosure)
   */
  const synthesizedSplitPayPaymentValues =
    computeDefaultMerchantPaymentTypeValues(
      'SPLITPAY',
      merchantPaymentProducts,
      displayInterestRateBPS
    );

  /**
   * RBC - Not used
   * ADS - Used when promo offers are not available from the SDK (Cards, Disclosure)
   */
  const exampleInstallmentsPaymentTypeValues = computePaymentTypeValues(
    merchantPaymentProducts,
    EXAMPLE_INSTALLMENTS_PROMO_OFFER
  );

  const hasInstallments = !!(useSyntheticPaymentTypeValues
    ? synthesizedInstallmentsPaymentValues
    : sdkInstallmentsPaymentTypeValues);
  const hasSplitpay = !!(useSyntheticPaymentTypeValues
    ? synthesizedSplitPayPaymentValues
    : sdkSplitPayPaymentTypeValues);

  return (
    <>
      {typeof children === 'function'
        ? children({
            hasInstallments,
            hasSplitpay,
            sdkInstallmentsPaymentTypeValues:
              sdkInstallmentsPaymentTypeValues || null,
            isDefaultOrder,
            sdkSplitPayPaymentTypeValues: sdkSplitPayPaymentTypeValues || null,
            synthesizedInstallmentsPaymentValues,
            synthesizedSplitPayPaymentValues,
            useSyntheticPaymentTypeValues,
            exampleInstallmentsPaymentTypeValues,
          })
        : children}
    </>
  );
};

export default IntroScreen;
