import { ComputedPaymentTypeValues } from '@ads-bread/shared/bread/util';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { FCWithChildren } from '../../../lib/types';
import { useDynamicDisclosure } from '../../XPropsContext';
import { formattingValues } from './Intro';

type DisclaimersProps = {
  hasInstallments: boolean;
  hasSplitPay: boolean;
  installmentsPaymentTypeValues?: ComputedPaymentTypeValues | null;
  splitPayPaymentTypeValues?: ComputedPaymentTypeValues | null;
};

export const Disclaimers: FunctionComponent<DisclaimersProps> = ({
  hasInstallments,
  hasSplitPay,
  installmentsPaymentTypeValues,
  splitPayPaymentTypeValues,
}) => {
  const disclosures = useDynamicDisclosure();

  return (
    <div data-testid="intro-disclaimer">
      <div id="footnoteDisclosureSymbolInstallments">
        {installmentsPaymentTypeValues && hasInstallments && (
          <Disclaimer>
            <FormattedMessage
              defaultMessage="<sup>{disclosureSymbolInstallments}</sup> {installmentsDisclosure}"
              description="Installment only without product price"
              values={{
                ...formattingValues,
                installmentsDisclosure: disclosures?.installmentsDisclosureText,
                disclosureSymbolInstallments:
                  disclosures?.installmentsDisclosureSymbol,
              }}
            />
          </Disclaimer>
        )}
      </div>

      {splitPayPaymentTypeValues && hasSplitPay && (
        <>
          <Disclaimer>
            <span id="footnoteDisclosureSymbolSplitPay">
              <FormattedMessage
                defaultMessage="<sup>{disclosureSymbolSplitPay}</sup> {splitPayDisclosure}"
                description="SplitPay disclaimer"
                values={{
                  ...formattingValues,
                  splitPayDisclosure: disclosures?.splitPayDisclosureText,
                  disclosureSymbolSplitPay:
                    disclosures?.splitPayDisclosureSymbol,
                }}
              />
            </span>
          </Disclaimer>
        </>
      )}
      <Disclaimer>
        <FormattedMessage
          defaultMessage="{footer}"
          description="intro disclosure footer"
          values={{
            ...formattingValues,
            footer: disclosures?.footer,
          }}
        />
      </Disclaimer>
    </div>
  );
};

export const Disclaimer: FCWithChildren = ({ children }) => {
  return (
    <p
      className="text-xs leading-normal text-dark-blue-grey leading-5 mb-4"
      style={{ whiteSpace: 'pre-line' }}
    >
      {children}
    </p>
  );
};
